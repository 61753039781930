import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Inventory from './inventory'
import { baseStyles } from './menu-item'
import { useSafeReplaceQuery } from './use-safe-replace-query'

const attentionAnimation = keyframes`
  0% {
   transform: rotate(0);
  }

  15%{
    transform: rotate(0);
  }

  35%{
    transform: rotate(20deg);
  }

  40%{
    transform: rotate(-20deg);
  }

  45%{
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  55%{
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);
  &.animated {
    animation-name: ${attentionAnimation};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`

const DisabledLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const MenuLink = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['highlighted']),
})`
  ${baseStyles};
  color: ${props =>
    props.highlighted ? t.color('nav-highlight') : t.color('nav-item')};
  transition: color 150ms ease-in-out;

  &:active {
    box-shadow: inset 4px 0 0 0 ${t.color('nav-highlight')};
  }
`

export function PromotionsMenuItem(props) {
  const shouldAnimate = ReactRedux.useSelector(
    CombinedSelectors.hasPromotionNotifications
  )
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )
  const promotionsQuery = useSafeReplaceQuery({
    promotions: hasUnseenInventory ? 'inventory' : 'campaigns',
  })
  const match = ReactRouter.useRouteMatch({
    path: `?${promotionsQuery}`,
  })

  if (props.disabled) {
    return (
      <DisabledLink data-testid={props.testId}>
        <Icons.NotificationsOutlined />
        <Common.Box
          fontSize={2}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
        >
          {props.children}
        </Common.Box>
      </DisabledLink>
    )
  }

  return (
    <MenuLink
      data-testid={props.testId}
      to={`?${promotionsQuery}`}
      onClick={props.onClick}
      highlighted={shouldAnimate}
    >
      <AnimatedIcon className={shouldAnimate ? 'animated' : ''}>
        {match ? <Icons.Notifications /> : <Icons.NotificationsOutlined />}
      </AnimatedIcon>
      <Common.Box
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </MenuLink>
  )
}

PromotionsMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}

export default PromotionsMenuItem
