import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as configuration from './configuration'
import { ApgSeal } from './apg-seal'
import { AppVersion } from './app-version'
import { ClientType } from './constants'
import { HtmlContent } from './html-content'
import { SocialMediaLinks } from './social-media-links'
import { useCookieConsent } from './use-cookie-consent'
import { useGameInfoUrlQuery } from './use-game-info-url-query'

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

export function Footer() {
  const i18n = I18n.useI18n()
  const gameInfoQuery = useGameInfoUrlQuery()
  const location = ReactRouter.useLocation()
  const clientType = ReactRedux.useSelector(state =>
    configuration.getClientType(state.configuration)
  )
  const cookieConsentEnabled = useCookieConsent()
  const isMobile = R.equals(ClientType.MOBILE_BROWSER, clientType)

  /**
   * Hides the footer:
   * - When playing any game on mobile
   * - You're on the Experts Area route
   */

  if (
    (isMobile && R.includes(gameInfoQuery.mode, ['classic', 'blitz'])) ||
    R.includes('experts-area', location.pathname)
  ) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <Common.Box
        as="nav"
        color="g-text"
        display="flex"
        flexWrap="wrap"
        fontSize={2}
        borderTop="1px solid"
        borderColor="nav-border"
        justifyContent="center"
        pt={0}
      >
        <ReactRouter.Link
          to="/terms-and-conditions"
          data-testid="footer.link.terms"
        >
          <LinkWrapper>
            {i18n.translate('footer.terms-and-conditions')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/about" data-testid="footer.link.about">
          <LinkWrapper>{i18n.translate('footer.about-us')}</LinkWrapper>
        </ReactRouter.Link>
        {cookieConsentEnabled && (
          <ReactRouter.Link
            to="/cookie-policy"
            data-testid="footer.link.cookie-policy"
          >
            <LinkWrapper>{i18n.translate('footer.cookie-policy')}</LinkWrapper>
          </ReactRouter.Link>
        )}
        <ReactRouter.Link to="/faq" data-testid="footer.link.faq">
          <LinkWrapper>{i18n.translate('footer.faq')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/privacy-policy"
          data-testid="footer.link.privacy-policy"
        >
          <LinkWrapper>{i18n.translate('footer.privacy-policy')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/responsible-gaming"
          data-testid="footer.link.responsible-gaming"
        >
          <LinkWrapper>
            {i18n.translate('footer.responsible-gaming')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/security" data-testid="footer.link.security">
          <LinkWrapper>{i18n.translate('footer.security')}</LinkWrapper>
        </ReactRouter.Link>
        <a
          href="https://sweetspotaffiliates.com/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="footer.link.affiliate"
        >
          <LinkWrapper>{i18n.translate('footer.affiliate')}</LinkWrapper>
        </a>
      </Common.Box>
      <SocialMediaLinks />
      <Common.Box
        fontSize={2}
        color="g-text"
        opacity="0.5"
        textAlign="center"
        mx="auto"
        maxWidth="700px"
      >
        <HtmlContent
          html={{
            __html: i18n.translate('footer.legal-text.mga'),
          }}
          data-testid="footer.legal-text.mga"
        />
      </Common.Box>
      <Common.Box
        pt={0}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        <Common.Box
          as="a"
          p={0}
          display="flex"
          href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJakp0UWtGaFZFaDRkM2d2Y1haWWJtUlBNM292T0djOVBTSXNJblpoYkhWbElqb2lhalpGTXpWdlRXVlBkamxhUkhKWFZGZHZZVXRJWnowOUlpd2liV0ZqSWpvaVpqSmxPVGsyTkdNMU56SmtOelV5TXpKak56TXlaVEkyTkdZek1qSXlOR0ZqTVRobE1XUTROekU0WXpjMU9UQTBOR1UzTkdGak1UY3lNV0ZtT0RrME55SXNJblJoWnlJNklpSjk="
          target="_blank"
          rel="noopener noreferrer"
        >
          <LazyLoad
            once
            offset={100}
            height="28.35px"
            placeholder={<Common.Text fontSize={1}>GCB</Common.Text>}
          >
            <img src="/images/gcb.avif" alt="GCB license" height="28.35px" />
          </LazyLoad>
        </Common.Box>
        <Common.Space p={0}>
          <ApgSeal />
        </Common.Space>
        <Common.Space p={0}>
          <ReactRouter.Link
            to="/responsible-gaming"
            data-testid="footer.responsible-gaming.link"
          >
            <LazyLoad
              once
              offset={100}
              placeholder={<Common.Text fontSize={1}>18+</Common.Text>}
            >
              <img
                src={i18n.translate('18-plus')}
                alt="18+"
                height="34"
                data-testid="footer.18-plus-logo"
                style={{ verticalAlign: 'bottom' }}
              />
            </LazyLoad>
          </ReactRouter.Link>
        </Common.Space>
      </Common.Box>
      <Common.Box py={2}>
        <a
          href="https://sweetspotaffiliates.com/"
          target="_blank"
          data-testid="footer.sweetspot.link"
          rel="noopener noreferrer"
        >
          <LazyLoad
            once
            offset={100}
            height="28px"
            placeholder={<Common.Text fontSize={1}>Sweetspot</Common.Text>}
          >
            <img
              height="28"
              src={i18n.translate('sweetspot-logo')}
              alt="Sweetspot"
            />
          </LazyLoad>
        </a>
      </Common.Box>
      <AppVersion />
    </Common.Box>
  )
}

// for @loadable/components
export default Footer
