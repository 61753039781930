import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import * as t from '@rushplay/theme'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as icons from '../icons'
import { baseStyles } from '../menu-item'

// Same style as other menu-items
const MenuLink = styled.div`
  ${baseStyles};
  color: ${t.color('nav-item')};
  cursor: pointer;
`

const attentionAnimation = keyframes`
  0% {
   transform: rotate(0);
  }

  15%{
    transform: rotate(0);
  }

  35%{
    transform: rotate(20deg);
  }

  40%{
    transform: rotate(-20deg);
  }

  45%{
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  55%{
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);
  &.animated {
    animation-name: ${attentionAnimation};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`

export function SupportChatMenuItem(props) {
  const hasUnreadMsgs = props.unreadMessages > 0

  return (
    <MenuLink data-testid={props.testId} onClick={props.onClick}>
      <AnimatedIcon className={hasUnreadMsgs ? 'animated' : ''}>
        <common.Box color={hasUnreadMsgs ? 'nav-highlight' : null}>
          <icons.ChatOutlined />
        </common.Box>
      </AnimatedIcon>
      <common.Box
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
        fontFamily="head"
      >
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

SupportChatMenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  unreadMessages: PropTypes.number,
  testId: PropTypes.string,
  onClick: PropTypes.func,
}
