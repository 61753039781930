import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Promotions from './promotions'
import { EmptyPromotionInventoryCard } from './empty-promotion-inventory-card'
import { PromotionCard } from './promotion-card'

export function PromotionCampaigns() {
  const i18n = I18n.useI18n()

  const activePromotions = ReactRedux.useSelector(state =>
    Promotions.getActivePromotions(state.promotions, { dateNow: Date.now() })
  )
  const futurePromotions = ReactRedux.useSelector(state =>
    Promotions.getFuturePromotions(state.promotions, { dateNow: Date.now() })
  )

  return (
    <React.Fragment>
      {R.isEmpty(activePromotions) ? (
        <EmptyPromotionInventoryCard translationKey="promotion-notifications.none-active" />
      ) : (
        <React.Fragment>
          <Common.Box
            fontSize={3}
            fontWeight="bold"
            mb={0}
            style={{ textTransform: 'uppercase', letterSpacing: '0.05em' }}
          >
            {i18n.translate('promotion-notifications.available-offers.title')}
          </Common.Box>
          <Common.Box display="grid" gridGap={0} pb={1}>
            {activePromotions.reverse().map(item => (
              <Common.Timestamp key={item.key}>
                {timestamp => (
                  <PromotionCard
                    dateNow={timestamp}
                    activeTo={item.activeTo}
                    campaign={item.campaign}
                    name={item.name}
                    ctaLink={item.loggedInButtonLink}
                    id={item.key}
                    seen={item.seen}
                  />
                )}
              </Common.Timestamp>
            ))}
          </Common.Box>
        </React.Fragment>
      )}
      {!R.isEmpty(futurePromotions) && (
        <React.Fragment>
          <Common.Box flexShrink="0" pb={1}>
            <Common.Box
              fontSize={3}
              fontWeight="bold"
              mb={0}
              style={{ textTransform: 'uppercase', letterSpacing: '0.05em' }}
            >
              {i18n.translate('promotion-notifications.upcoming.title')}
            </Common.Box>
            <Common.Box display="grid" gridGap={0}>
              {futurePromotions.reverse().map(item => (
                <Common.Timestamp key={item.key}>
                  {timestamp => (
                    <PromotionCard
                      upcoming
                      dateNow={timestamp}
                      activeTo={item.activeTo}
                      activeFrom={item.activeFrom}
                      campaign={item.campaign}
                      ctaLink={item.loggedInButtonLink}
                      id={item.key}
                      seen={item.seen}
                    />
                  )}
                </Common.Timestamp>
              ))}
            </Common.Box>
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
