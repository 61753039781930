import * as ReactRedux from 'react-redux'

import * as Herz from '@rushplay/herz'
import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'

import * as CombinedSelectors from '../combined-selectors'
import * as Player from '../player'
import * as Configuration from '../configuration'

import { RegistrationDrawer as Component } from './registration-drawer'

export const RegistrationDrawer = ReactRedux.connect(
  state => ({
    affiliateClickId: CombinedSelectors.getAffiliateClickId(state),
    btag: Analytics.getBtag(state.analytics),
    clientType: Configuration.getClientType(state.configuration),
    currency: Player.getCurrency(state.player),
    shouldVerifyPhone: Configuration.getLoggedOutPhoneVerification(
      state.configuration
    ),
    utmCampaign: Analytics.getUtmCampaign(state.analytics),
    utmMedium: Analytics.getUtmMedium(state.analytics),
    utmSource: Analytics.getUtmSource(state.analytics),
  }),
  dispatch => ({
    onPhoneValidation: (data, onFailure, onSuccess) =>
      dispatch(
        Api.validatePhoneNumber(data.countryCallingCode, data.phone, {
          success: onSuccess,
          failure: res => {
            onFailure([
              `phone-validation.${Herz.Utils.Strings.toKebabCase(
                res.value.message
              )}`,
            ])
          },
          version: 2,
        })
      ),
    onPhoneVerificationCodeRequest: (data, onFailure, onSuccess) =>
      dispatch([
        Processes.start('PHONE_VERIFICATION_LOADING'),
        Api.requestPhoneVerificationCode(data, {
          success: () => {
            onSuccess()
            return Processes.stop('PHONE_VERIFICATION_LOADING')
          },
          failure: res => {
            onFailure([
              `phone-verification.${Herz.Utils.Strings.toKebabCase(
                res.value.message
              )}`,
            ])
            return Processes.stop('PHONE_VERIFICATION_LOADING')
          },
          version: 1,
        }),
      ]),
  })
)(Component)

// for @loadable/components
export default RegistrationDrawer
