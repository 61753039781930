import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as Recompose from 'recompose'

import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'

import * as Configuration from '../configuration'

import { PhoneVerificationCodeInput as Component } from './phone-verification-code-input'

export const PhoneVerificationCodeInput = R.compose(
  ReactRedux.connect(
    state => ({
      enabled: Configuration.getLoggedOutPhoneVerification(state.configuration),
    }),
    dispatch => ({
      onResend: data =>
        dispatch(
          Api.requestPhoneVerificationCode(data, {
            failure: () =>
              Notifications.add({
                message: 'errors.phone-verification-request.failed',
                level: 'error',
              }),
            version: 1,
          })
        ),
    })
  ),
  Recompose.branch(props => !props.enabled, Recompose.renderNothing)
)(Component)
