import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import { typography } from 'styled-system'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as icons from './icons'

function borderColor(props) {
  if (props.valid) {
    return 'success'
  } else if (props.invalid) {
    return 'danger'
  } else {
    return 'inputBorder'
  }
}

const Input = styled.input`
  opacity: 0;
  margin-right: -1.2em;
`

const InputBase = styled.div`
  border-radius: 0.1em;
  height: 1.25em;
  width: 1.25em;
  min-width: 1.25em;
  position: relative;
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    css({
      outline:
        props.valid || props.invalid || props.disabled || props.focused
          ? '2px solid'
          : '1px solid',
      outlineColor: borderColor(props),
      backgroundColor: 'input-background',
      'input[type=checkbox]:checked + &': {
        borderColor: 'checkbox-checked',
        bg: 'checkbox-checked',
        color: 'g-text',
      },
      'input[type=checkbox]:not([checked]):disabled + &': {
        bg: 'white',
        borderColor: 'disabled',
        cursor: 'not-allowed',
      },
      'input[type=checkbox]:disabled:checked + &': {
        bg: 'disabled',
        borderColor: 'disabled',
        cursor: 'not-allowed',
        color: 'g-text',
      },
    })};
  ${props => props.focus && 'border-color: #0D989F !important;'}
  ${typography}
`

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  pointer-events: inherit;
  padding: 2px;
`

export function Checkbox(props) {
  const [focus, setFocus] = React.useState(false)

  const valid = R.isEmpty(props.errors)
  const visited = !R.includes(props.status, [
    Forms.FieldStatus.PRISTINE,
    Forms.FieldStatus.ABSENT,
  ])
  const invalid = !valid && visited

  return (
    <Label data-testid={`${props.name}.checkbox.label`}>
      <Input
        name={props.name}
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        value={props.value}
        data-testid={`${props.name}.checkbox`}
        onChange={props.onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <InputBase
        fontSize={props.fontSize}
        focus={focus}
        invalid={invalid}
        valid={valid && visited && props.checked}
      >
        <div>{props.checked && <icons.Done />}</div>
      </InputBase>
      {props.label && (
        <Common.Box pl={1} color="g-text" fontSize={2}>
          {props.label}
        </Common.Box>
      )}
    </Label>
  )
}

Checkbox.propTypes = {
  fontSize: 2,
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  initialValue: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scope: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  status: PropTypes.string,
}
