import * as ReactRedux from 'react-redux'
import * as Urql from 'urql'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import gql from 'graphql-tag'

import * as Analytics from '@rushplay/analytics'
import * as I18n from '@rushplay/i18n'
import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Notifications from '@rushplay/notifications'

import * as SessionRedux from '../session'
import * as ServerConfiguration from '../server-configuration'
import * as Suspense from '../suspense'
import * as Utils from '../utils'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form'
import { RegistrationStep } from '../constants'
import { ScrollLock } from '../scroll-lock'

import { dataSchema } from './data-schema'

const validateEmailMutation = gql`
  mutation ValidateEmail($email: String) {
    validateEmail(email: $email) {
      available
    }
  }
`

export function RegistrationDrawer(props) {
  const i18n = I18n.useI18n()
  const history = ReactRouter.useHistory()
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const [, validateEmail] = Urql.useMutation(validateEmailMutation)
  const [codeSentTo, setCodeSentTo] = React.useState(null)
  const [errors, setErrors] = React.useState([])
  const session = Herz.Auth.useSession()
  const dispatch = ReactRedux.useDispatch()
  const { countries, locale } = ServerConfiguration.useContext()
  const countryCallingCodes = React.useMemo(() => {
    return Herz.Utils.Arrays.pluck('countryCode', countries)
      .filter(Boolean)
      .map(e => e.toString())
      .sort()
  }, [countries])

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: countryCallingCodes,
        country: Herz.Utils.Arrays.pluck('name', countries),
        maxYear: new Date(Date.now()).getFullYear() - 18,
        shouldVerifyPhone: props.shouldVerifyPhone,
      }),
    [props.shouldVerifyPhone, countries]
  )

  function onClearErrors() {
    setErrors([])
  }

  function handleSubmit(data) {
    const countryCode = countries.find(country => country.name === data.country)
    const userData = {
      affiliateClickId: props.affiliateClickId,
      allowEmail: data.promotional,
      allowSms: data.promotional,
      birthdate: `${data.bdayDay}/${data.bdayMonth}/${data.bdayYear}`,
      city: data.city,
      clientType: props.clientType,
      countryCallingCode: `+${data.countryCallingCode}`,
      countryCode: countryCode.alpha2,
      currency: props.currency,
      email: data.email,
      firstName: data.firstName,
      // Hardcodes gender because it's required on BE, but we don't care
      gender: 'male',
      personId: data.personId,
      generateUsername: true,
      language: locale.language,
      lastName: data.lastName,
      phoneNumber: data.phonenumber,
      netrefererBTag: props.btag,
      password: data.password,
      passwordConfirmation: data.password,
      phoneVerificationCode: data.phoneVerificationCode,
      street: data.street,
      utmCampaign: props.utmCampaign,
      utmMedium: props.utmMedium,
      utmSource: props.utmSource,
      postalCode: data.zip,
    }

    dispatch(Analytics.registrationStarted({ registrationType: 'inhouse' }))

    session.create(userData).then(response => {
      if (response.error) {
        return dispatch(
          Notifications.add({
            level: 'error',
            message: Utils.Errors.normalizeAuthenticationError(
              response.error.message
            ),
          })
        )
      }

      const { token, createdAt } = response.createPlayer.session
      // Update token in redux state as some packages still rely on redux state token
      dispatch([SessionRedux.update(token, createdAt)])

      history.replace(history.location.pathname)
    })
  }

  function handlePhoneValidation(data) {
    const userData = {
      countryCallingCode: `+${data.countryCallingCode}`,
      phone: data.phonenumber,
      language: locale.language,
    }

    function failure(errors) {
      setErrors(errors)
      setCodeSentTo('')
    }

    function success() {
      setErrors([])
      setStep(RegistrationStep.Identity)
      setCodeSentTo(`${data.countryCallingCode}${data.phonenumber}`)
    }

    function validationSuccess() {
      if (props.shouldVerifyPhone) {
        // TODO: rewrite to gql
        props.onPhoneVerificationCodeRequest(userData, failure, success)
      } else {
        success()
      }
    }

    validateEmail({ email: data?.email })
      .then(response => {
        if (!response.data?.validateEmail?.available) {
          setErrors(['errors.email-exists'])
          return dispatch(
            Notifications.add({
              level: 'error',
              message: 'errors.email-exists',
            })
          )
        }
        // TODO: rewrite to gql as well
        props.onPhoneValidation(userData, failure, validationSuccess)
      })
      .catch(() => {
        setErrors(['errors.general.unknown'])
        return Notifications.add({
          level: 'error',
          message: 'errors.general.unknown',
        })
      })
  }

  return (
    <Forms.Provider
      name="register"
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (
          (!formErrors || Object.keys(errors).length === 0) &&
          step > RegistrationStep.Credentials
        ) {
          handleSubmit(data)
        }

        if (codeSentTo !== `${data.countryCallingCode}${data.phonenumber}`) {
          dispatch(
            Analytics.registrationStarted({ registrationType: 'inhouse' })
          )
          handlePhoneValidation(data)
        }

        if (
          data.email &&
          data.password &&
          data.phonenumber &&
          data.countryCallingCode &&
          codeSentTo &&
          codeSentTo !== `${data.countryCallingCode}${data.phonenumber}`
        ) {
          setStep(RegistrationStep.Identity)
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials
            ? () => setStep(RegistrationStep.Credentials)
            : null
        }
        activeQueryName="register"
        title={i18n.translate('register.title')}
      >
        <Suspense.Boundary>
          <Common.Box pb={6} color="g-text">
            <ScrollLock />
            <ProgressBar step={step} steps={2} />
            <RegistrationForm
              onClearErrors={onClearErrors}
              countryCallingCodes={countryCallingCodes}
              errors={errors}
              step={step}
            />
          </Common.Box>
        </Suspense.Boundary>
      </QueryDrawer>
    </Forms.Provider>
  )
}

RegistrationDrawer.propTypes = {
  affiliateClickId: PropTypes.string,
  btag: PropTypes.string,
  clientType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  shouldVerifyPhone: PropTypes.bool,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  onPhoneVerificationCodeRequest: PropTypes.func.isRequired,
  onPhoneValidation: PropTypes.func.isRequired,
}
