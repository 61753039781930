import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Suspense from '../suspense'
import { Footer } from '../footer'
import { Home } from '../home'
import { LocationBanner } from '../locale'
import { LocationPicker } from '../locale/change-location'
import { LoyaltyDrawer } from '../loyalty-drawer'
import { MainMenu } from '../main-menu'
import { PrivateRoute } from '../private-route'
import { PromotionsDrawer } from '../promotions-drawer'
import { Registration } from '../registration'
import { SportsbookDirect } from '../sports-direct'
import { useCookieConsent } from '../use-cookie-consent'
import { useScrollToTop } from '../use-scroll-to-top'

const Account = loadable(() => import('../account'))
const Casino = loadable(() => import('../casino'))
const DevTools = loadable(() => import('../dev-tools'))
const ExpertsArea = loadable(() => import('../experts-area'))
const Faq = loadable(() => import('../faq'))
const GameInfoPage = loadable(() => import('../game-info-page'))
const LiveCasino = loadable(() => import('../live-casino'))
const Logout = loadable(() => import('../logout'))
const LoginDrawer = loadable(() => import('../login-drawer'))
const NotFound = loadable(() => import('../not-found'))
const PasswordRecovery = loadable(() => import('../password-recovery'))
const PasswordReset = loadable(() => import('../password-reset'))
const PromotionTermsAndConditionsPage = loadable(() =>
  import('../promotion-terms-and-conditions-page')
)
const StaticPage = loadable(() => import('../static-page'))
const TermsAndConditions = loadable(() => import('../terms-and-conditions'))
const Wallet = loadable(() => import('../wallet'))

export function Primary() {
  const [ref, handleScrollToTop] = useScrollToTop()
  const location = ReactRouter.useLocation()
  const session = Herz.Auth.useSession()
  const cookieConsentEnabled = useCookieConsent()

  return (
    <React.Fragment>
      <ReactRouter.Route path="/" component={MainMenu} />
      <Common.Box flexGrow="1" ref={ref}>
        {!session.authenticated && <LocationBanner />}
        <Common.Box
          minHeight="var(--window-inner-height, 100vh)"
          display="flex"
        >
          <Suspense.Boundary>
            <ReactRouter.Switch>
              <ReactRouter.Route exact path="/">
                <Home onScrollToTop={handleScrollToTop} />
              </ReactRouter.Route>

              <ReactRouter.Redirect
                from="/locks"
                to={{ pathname: '/', search: location.search }}
              />
              <ReactRouter.Route
                path="/change-location"
                component={LocationPicker}
              />

              <ReactRouter.Route
                path="/casino/games/:gameId"
                component={GameInfoPage}
              />

              <ReactRouter.Route path="/casino" component={Casino} />

              <ReactRouter.Route path="/sports" component={SportsbookDirect} />

              <PrivateRoute path="/account" component={Account} />

              <ReactRouter.Route path="/live-casino" component={LiveCasino} />

              {process.env.RAZZLE_EXPERTS_AREA_ENABLED === '1' && (
                <ReactRouter.Route
                  path="/experts-area"
                  component={ExpertsArea}
                />
              )}

              <ReactRouter.Route
                exact
                path="/password-recovery"
                component={PasswordRecovery}
              />

              <ReactRouter.Redirect
                exact
                from="/password/:token"
                to="/password-reset/:token"
              />
              <ReactRouter.Route
                path="/password-reset/:token"
                component={PasswordReset}
              />

              <ReactRouter.Route
                path={R.filter(Boolean, [
                  '/(about)',
                  cookieConsentEnabled && '/(cookie-policy)',
                  '/(privacy-policy)',
                  '/(responsible-gaming)',
                  '/(security)',
                ])}
                component={StaticPage}
              />

              <ReactRouter.Route
                path="/promotions/:key"
                component={PromotionTermsAndConditionsPage}
              />

              <ReactRouter.Route path="/faq" component={Faq} />

              <ReactRouter.Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />

              <ReactRouter.Route path="/logout" component={Logout} />

              {/* Keep existing functionality for people who still have it for a while */}
              <ReactRouter.Redirect
                from="/login"
                to={{ pathname: '/', search: '?login=me' }}
              />

              <ReactRouter.Route path="/dev-tools">
                <DevTools />
              </ReactRouter.Route>

              <ReactRouter.Route component={NotFound} />
            </ReactRouter.Switch>
          </Suspense.Boundary>
        </Common.Box>
        <Footer />
      </Common.Box>

      <PromotionsDrawer />
      <LoyaltyDrawer />
      <Wallet />
      <LoginDrawer />
      <Registration />
    </React.Fragment>
  )
}

// For @loadable/component
export default Primary
