import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Inventory from './inventory'
import { Divider } from './divider'
import { EmptyPromotionInventoryCard } from './empty-promotion-inventory-card'
import { InventoryCard } from './inventory-card'

export function PromotionInventory() {
  const i18n = I18n.useI18n()

  const dateNow = Date.now()

  const inventoryItems = ReactRedux.useSelector(state =>
    Inventory.getActiveItems(state.inventory, { dateNow })
  )

  const futureInventoryItems = ReactRedux.useSelector(state =>
    Inventory.getFutureItems(state.inventory, { dateNow })
  )

  return (
    <React.Fragment>
      {R.and(R.isEmpty(inventoryItems), R.isEmpty(futureInventoryItems)) ? (
        <EmptyPromotionInventoryCard translationKey="promotion-inventory.no-items" />
      ) : (
        <Common.Box display="grid" gridGap={0} pb={1}>
          {inventoryItems.map(item => (
            <Common.Timestamp key={item.id}>
              {timestamp => <InventoryCard dateNow={timestamp} {...item} />}
            </Common.Timestamp>
          ))}
        </Common.Box>
      )}
      {!R.isEmpty(futureInventoryItems) && (
        <Common.Box flexShrink="0" pb={1}>
          <Common.Space pt={1} pb={1}>
            <Divider />
          </Common.Space>
          <Common.Box pb={1} fontSize={3} fontWeight="bold" textAlign="center">
            {i18n.translate('promotion-inventory.upcoming')}
          </Common.Box>
          <Common.Box display="grid" gridGap={0}>
            {futureInventoryItems.map(item => (
              <Common.Timestamp key={item.key}>
                {timestamp => <InventoryCard dateNow={timestamp} {...item} />}
              </Common.Timestamp>
            ))}
          </Common.Box>
        </Common.Box>
      )}
    </React.Fragment>
  )
}
