import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

import * as Session from './session'

export function PrivateRoute({ component: Component, ...props }) {
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )

  return (
    <Route
      {...props}
      render={({ ...props }) => {
        if (authenticated) {
          return <Component {...props} />
        }

        return <Redirect to={{ pathname: '/' }} />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  path: PropTypes.string,
}
