import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as ServerConfig from '../server-configuration'
import { Flag } from '../flags'

const Language = styled.span`
  padding-left: 0.5em;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export function LocationInfo() {
  const { country, locale } = ServerConfig.useContext()
  const i18n = I18n.useI18n()

  return (
    <Common.Box fontSize={2} p={1} textAlign="center">
      <Common.Box
        as={ReactRouter.Link}
        to="/change-location"
        display="block"
        data-testid="location-info-link"
      >
        <Flag code={country.alpha2} />
        <Language>
          {i18n.translate(`l10n.language.${locale.language}`)}
        </Language>
      </Common.Box>
    </Common.Box>
  )
}
