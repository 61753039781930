import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Api from '@rushplay/api-client'
import * as Theme from '@rushplay/theme'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Analytics from './analytics'
import * as Promotions from './promotions'
import * as Inventory from './inventory'
import * as Suspense from './suspense'
import * as Session from './session'
import * as ServerConfiguration from './server-configuration'
import { PromotionCampaigns } from './promotion-campaigns'
import { PromotionInventory } from './promotion-inventory'
import { QueryDrawer } from './query-drawer'
import { ScrollLock } from './scroll-lock'
import { getSearchQuery } from './get-search-query'
import { usePrev } from './use-prev'
import { useSafeUpdateQuery } from './use-safe-update-query'

const TabMenuItem = styled(ReactRouter.Link, {
  shouldForwardProp: Common.noneOf(['active']),
})`
  ${props =>
    css({
      p: 1,
      backgroundColor: 'g-bg',
      color: props.active ? 'nav-highlight' : 'g-text',
      fontFamily: 'head',
    })};
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  flex: 1;
  box-shadow: ${props =>
    props.active
      ? `inset 0 -3px 0 0 ${Theme.color('nav-highlight')(props)}`
      : 'inset 0 -1px 0 0 rgba(255, 255, 255, 0.2)'};
  cursor: pointer;
  user-select: none;
`

export function PromotionsDrawer() {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const query = getSearchQuery(location)
  const prevQuery = usePrev(query)
  const campaignsQuery = useSafeUpdateQuery({ promotions: 'campaigns' })
  const inventoryQuery = useSafeUpdateQuery({ promotions: 'inventory' })

  const { country } = ServerConfiguration.useContext()

  React.useEffect(() => {
    if (prevQuery.promotions !== query.promotions) {
      if (query.promotions) {
        // Fetch promotions and inventory on open
        if (authenticated) {
          dispatch([Promotions.fetch(), Inventory.fetch()])
        } else {
          dispatch(Promotions.fetch(country.alpha2))
        }
        if (query.promotions === 'campaigns') {
          dispatch(
            Analytics.openPromotionMenu({
              path: location.pathname,
              title: document.title,
            })
          )
        }
      } else {
        // Mark as read when drawer closes
        if (authenticated) {
          dispatch(
            Api.markNotificationsAsSeen({
              success: () => [
                Promotions.markAllAsSeen(),
                Inventory.markAllAsSeen(),
              ],
              version: 1,
            })
          )
        } else {
          dispatch(Promotions.markAllAsSeen())
        }
      }
    }
  }, [authenticated, prevQuery, query])

  return (
    <QueryDrawer
      activeQueryName="promotions"
      title={i18n.translate('promotion-notifications.title')}
    >
      <Suspense.Boundary>
        <Common.Box pb={1} flexGrow="1" display="flex" flexDirection="column">
          <ScrollLock />
          <Common.Box
            fontSize={2}
            color="g-text"
            display="flex"
            mx="-16px"
            mb={1}
            flexShrink="0"
            wordBreak="break-all"
          >
            <TabMenuItem
              to={`?${campaignsQuery}`}
              active={query.promotions === 'campaigns'}
            >
              {i18n.translate('promotions.tab.campaigns')}
            </TabMenuItem>
            <TabMenuItem
              to={`?${inventoryQuery}`}
              active={query.promotions === 'inventory'}
            >
              {i18n.translate('promotions.tab.inventory')}
            </TabMenuItem>
          </Common.Box>

          {query.promotions === 'campaigns' && <PromotionCampaigns />}

          {query.promotions === 'inventory' && <PromotionInventory />}
        </Common.Box>
      </Suspense.Boundary>
    </QueryDrawer>
  )
}

// for @loadable/components
export default PromotionsDrawer
