import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as player from './player'

const Track = styled.path`
  ${css({
    stroke: 'loyalty-program-progressbar-track',
  })};
`

const Bar = styled.path`
  ${css({
    stroke: 'loyalty-program-progress',
  })};
  transition: stroke-dashoffset 300ms ease;
`

const dashlength = 305
const dashlengthPercent = dashlength / 100

export function LoyaltyProgramRoundProgressBar(props) {
  const percent = R.defaultTo(
    0,
    useSelector(state => player.getBetBackProgressPercentage(state.player))
  )
  const dashoffset = dashlength - percent * dashlengthPercent

  return (
    <common.Box display="flex" justifyContent="center">
      <common.Box as="svg" height={props.height} viewBox="0 0 140 70">
        <Track
          d="m77.4405,42.093651l1.809,2.087c7.789,8.762 13.77,15.16 23.784,15.16c13.492,0 24.341,-10.849 24.341,-24.34s-10.85,-24.48 -24.34,-24.341s-18.5,10.014 -30.74,23.784c-12.38,12.24 -21.837,24.898 -35.33,24.898s-24.339,-10.85 -24.339,-24.342s10.85,-24.341 24.34,-24.341c10.155,0 17.805,7.372 25.594,15.578l2.364,2.504"
          fill="none"
          strokeWidth="10"
          strokeLinecap="butt"
          strokeMiterlimit="10"
        />
        <Bar
          d="m77.4405,42.093651l1.809,2.087c7.789,8.762 13.77,15.16 23.784,15.16c13.492,0 24.341,-10.849 24.341,-24.34s-10.85,-24.48 -24.34,-24.341s-18.5,10.014 -30.74,23.784c-12.38,12.24 -21.837,24.898 -35.33,24.898s-24.339,-10.85 -24.339,-24.342s10.85,-24.341 24.34,-24.341c10.155,0 17.805,7.372 25.594,15.578l2.364,2.504"
          fill="none"
          strokeWidth="10"
          strokeLinecap="butt"
          strokeMiterlimit="10"
          strokeDasharray={dashlength}
          strokeDashoffset={dashoffset}
        />
      </common.Box>
    </common.Box>
  )
}

LoyaltyProgramRoundProgressBar.defaultProps = {
  height: ['22px', '24px'],
}

LoyaltyProgramRoundProgressBar.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}
