import * as Constants from './constants'
import { useSafeReplaceQuery } from './use-safe-replace-query'

export function useMenuQueries() {
  const depositQuery = useSafeReplaceQuery({
    wallet: Constants.TransactionType.DEPOSIT,
  })
  const withdrawalQuery = useSafeReplaceQuery({
    wallet: Constants.TransactionType.WITHDRAWAL,
  })
  const loyaltyQuery = useSafeReplaceQuery({ 'loyalty-program': 'cashback' })
  const loginQuery = useSafeReplaceQuery({ login: 'me' })
  const registerQuery = useSafeReplaceQuery({ register: 'me' })

  return {
    depositQuery,
    loginQuery,
    loyaltyQuery,
    registerQuery,
    withdrawalQuery,
  }
}
