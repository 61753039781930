import React from 'react'

import * as Common from '@rushplay/common'

import * as Icons from './icons'

export function SocialMediaLinks() {
  return (
    <Common.Box
      py={1}
      display="grid"
      gridGap={1}
      gridTemplateColumns="repeat(3, 1fr)"
      fontSize={4}
      opacity={0.7}
    >
      <a
        href="https://www.youtube.com/channel/UC5BcJ7CeURc3Ak4CpSdLgGw"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icons.Youtube />
      </a>
      <a
        href="https://twitter.com/amuletobet"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icons.Twitter />
      </a>
      <a
        href="https://www.instagram.com/amuletobet/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icons.Instagram />
      </a>
    </Common.Box>
  )
}
