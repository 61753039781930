import * as EmotionTheming from 'emotion-theming'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import { Divider } from '../divider'
import { Heading } from '../heading'
import { LocationInfo, LocationPicker } from '../locale'

const SORRY = {
  ch: '對不起',
  dk: 'Undskyld',
  en: 'Sorry',
  it: 'Scusi',
  ja: 'ごめんなさい',
  po: 'Me desculpe',
  ro: 'Pardon',
  ru: 'Простите',
  sv: 'Förlåt',
}

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

function shuffleArray(array, result = []) {
  if (array.length === 0) {
    return result
  }

  if (array.legnth === 1) {
    return R.concat(result, array)
  }

  const index = randomInt(0, array.length - 1)

  return shuffleArray(R.remove(index, 1, array), R.append(array[index], result))
}

function Home() {
  const i18n = I18n.useI18n()
  const [keys, setKeys] = React.useState(['ro'])
  const key = R.head(keys)

  React.useEffect(() => {
    const id = window.setTimeout(() => {
      if (keys.length > 1) {
        setKeys(R.tail(keys))
      } else {
        setKeys(shuffleArray(Object.keys(SORRY)))
      }
    }, 3000)

    return () => window.clearTimeout(id)
  }, [keys])

  return (
    <Common.Box p={[1, 3, 5]} textAlign="center">
      <Heading as="div" color="g-text" level={1}>
        {SORRY[key]}
      </Heading>

      <Common.Box fontSize="72px" py={[1, 3]}>
        😢
      </Common.Box>
      <Heading as="div" color="g-text" level={3}>
        {i18n.translate('unsupported-market.title')}
      </Heading>
      <Common.Box maxWidth="300px" mx="auto" py={[1, 3]}>
        <Divider />
      </Common.Box>
      <Common.Box fontSize={1}>
        <p>{i18n.translate('unsupported-market.change-region')}</p>
        <LocationInfo />
      </Common.Box>
    </Common.Box>
  )
}

export function BlockedMarket() {
  const theme = EmotionTheming.useTheme()
  return (
    <Common.Box
      backgroundImage={`url(${theme['bg-pattern-default']}), linear-gradient(to bottom, ${theme.colors['page-gradient-start']} 2%,
      ${theme.colors['page-gradient-middle']} 53%, ${theme.colors['page-gradient-stop']})`}
      backgroundSize={['auto 200%, cover']}
      backgroundPosition="center -300px, center"
      flexGrow="1"
      overflowX="hidden"
      style={{
        gridColumn: '1/3',
      }}
    >
      <Common.Box minHeight="var(--window-inner-height, 100vh)">
        <ReactRouter.Switch>
          <ReactRouter.Route exact path="/">
            <Home />
          </ReactRouter.Route>

          <ReactRouter.Route path="/change-location">
            <LocationPicker />
          </ReactRouter.Route>

          <ReactRouter.Redirect to="/" />
        </ReactRouter.Switch>
      </Common.Box>
    </Common.Box>
  )
}

// For @loadable/component
export default BlockedMarket
