import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import * as Herz from '@rushplay/herz'
import * as Processes from '@rushplay/processes'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as ServerConfiguration from '../server-configuration'
import * as Icons from '../icons'
import { Checkbox } from '../checkbox'
import { FieldBuilder } from '../field-builder'
import { InputField } from '../input-field'
import { InputPasswordField } from '../input-password-field'
import { PhoneVerificationCodeInput } from '../phone-verification-code-input'
import { RegistrationStep } from '../constants'
import { SelectField } from '../select-field'
import { Spinner } from '../spinner'
import { SubmitButton } from '../submit-button'

import * as uiSchema from './ui-schema'

export function RegistrationForm(props) {
  const i18n = I18n.useI18n()

  const { country } = ServerConfiguration.useContext()

  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['PHONE_VERIFICATION_LOADING'],
    })
  )

  if (!country.enabled) {
    return (
      <React.Fragment>
        <Common.Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          pt="2"
        >
          <Common.Box fontSize={8} color="warning">
            <Icons.Warning />
          </Common.Box>
          <Common.Box fontSize={3} p={1}>
            {i18n.translate('errors.unsupported-country')}
          </Common.Box>
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {props.step === RegistrationStep.Credentials && (
        <React.Fragment>
          <Common.Box display="grid" gridGap={0}>
            <InputField
              onClearErrors={props.onClearErrors}
              autoComplete="email"
              autoFocus
              inputMode="email"
              prependIcon={<Icons.Email />}
              scope="#/properties/email"
              error={
                props.errors && props.errors.includes('errors.email-exists')
              }
            />
            <InputPasswordField
              onClearErrors={props.onClearErrors}
              autoComplete="password"
              prependIcon={<Icons.Lock />}
              scope="#/properties/password"
            />
            <Common.Box
              display="grid"
              gridTemplateColumns="80px 1fr"
              gridGap={1}
            >
              <SelectField
                options={[...new Set(props.countryCallingCodes)].map(
                  option => ({
                    label: `register.country-calling-code.${option}`,
                    value: option,
                  })
                )}
                initialValue={country.countryCode.toString()}
                autoComplete="tel-country-code"
                scope="#/properties/countryCallingCode"
              />
              <InputField
                onClearErrors={props.onClearErrors}
                error={
                  props.errors &&
                  (props.errors.includes('phone-validation.phone-invalid') ||
                    props.errors.includes('phone-validation.phone-not-unique'))
                }
                autoComplete="tel"
                inputMode="tel"
                scope="#/properties/phonenumber"
              />
            </Common.Box>
          </Common.Box>

          {props.errors && Object.keys(props.errors).length != 0 && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {props.errors.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              })}
            </Common.Box>
          )}

          <Common.Box pt={2} textAlign="center">
            <SubmitButton
              onlyScopes={[
                '#/properties/email',
                '#/properties/password',
                '#/properties/phonenumber',
              ]}
              loading={loading}
            >
              {loading ? (
                <Common.Box
                  maxWidth="30px"
                  maxHeight="30px"
                  mt="-15px"
                  mb="5px"
                >
                  <Spinner />
                </Common.Box>
              ) : (
                i18n.translate('register.step1.cta')
              )}
            </SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}

      {props.step === RegistrationStep.Identity && (
        <React.Fragment>
          <FieldBuilder
            uiSchema={uiSchema.step2}
            name="registration-step2"
            initialValues={{
              '#/properties/country': country.name,
              '#/properties/terms': true,
              '#/properties/privacy': true,
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/terms': {
                  return TermsCheckbox
                }

                case '#/properties/personId': {
                  return CpfInputField
                }

                case '#/properties/privacy': {
                  return PrivacyCheckbox
                }

                case '#/properties/promotional': {
                  return AllowCheckbox
                }

                case '#/properties/phoneVerificationCode': {
                  return PhoneVerificationCodeInput
                }

                default: {
                  return null
                }
              }
            }}
          />
          {props.errors && Object.keys(props.errors).length != 0 && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {props.errors.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              })}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <SubmitButton>{i18n.translate('register.step2.cta')}</SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  errors: PropTypes.array,
  step: PropTypes.number.isRequired,
  countryCallingCodes: PropTypes.array.isRequried,
  onClearErrors: PropTypes.func.isRequired,
}

function AllowCheckbox(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  React.useEffect(() => {
    if (props.initialValue) {
      field.onChangeValue(props.initialValue)
    }
  }, [props.initialValue])

  return (
    <Checkbox
      id={field.name}
      name={field.name}
      errors={field.errors}
      status={field.status}
      checked={field.value}
      scope={props.scope}
      label={props.label ? props.label : i18n.translate(field.label)}
      value={field.value}
      onChange={() =>
        field.onChangeValue(
          typeof field.value != 'undefined' ? !field.value : !props.initialValue
        )
      }
    />
  )
}

AllowCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scope: PropTypes.string,
  initialValue: PropTypes.bool,
}

function TermsCheckbox(props) {
  return (
    <AllowCheckbox
      initialValue={props.initialValue}
      label={<LabelWithLink scope={props.scope} to="/terms-and-conditions" />}
      scope={props.scope}
    />
  )
}

TermsCheckbox.propTypes = {
  scope: PropTypes.string,
  initialValue: PropTypes.bool,
}

function PrivacyCheckbox(props) {
  return (
    <AllowCheckbox
      initialValue={props.initialValue}
      label={<LabelWithLink scope={props.scope} to="/privacy-policy" />}
      scope={props.scope}
    />
  )
}

PrivacyCheckbox.propTypes = {
  scope: PropTypes.string,
  initialValue: PropTypes.bool,
}

function useFormatCpf() {
  const prevZipCodeRef = React.useRef('')

  function formatZipCode(inputCPF = '') {
    if (inputCPF.length === 3 || inputCPF.length === 7) {
      if (!prevZipCodeRef?.current?.endsWith('.')) {
        const formattedValue = inputCPF.concat('.')
        prevZipCodeRef.current = formattedValue
        return formattedValue
      }
    }
    if (inputCPF.length === 11) {
      if (!prevZipCodeRef?.current?.endsWith('-')) {
        const formattedValue = inputCPF.concat('-')
        prevZipCodeRef.current = formattedValue
        return formattedValue
      }
    }
    prevZipCodeRef.current = inputCPF
    return inputCPF
  }

  return formatZipCode
}

export function CpfInputField(props) {
  const formatCpf = useFormatCpf()
  return (
    <InputField initialValue="" normalize={formatCpf} scope={props.scope} />
  )
}

CpfInputField.propTypes = {
  scope: PropTypes.string,
}

function LabelWithLink(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope)
  return (
    <Common.Box>
      {i18n.translate(field.label)}
      <Common.Box
        as={ReactRouter.Link}
        to={props.to}
        style={{ textDecoration: 'underline' }}
        data-testid={`registartion-drawer.${props.to.substr(1)}.link`}
      >
        {`${i18n.translate(
          `${Herz.Utils.Strings.toKebabCase(props.to.slice(1))}`
        )}`}
      </Common.Box>
    </Common.Box>
  )
}

LabelWithLink.propTypes = {
  scope: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}
