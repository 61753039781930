import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Urql from 'urql'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Constants from './constants'
import * as ServerConfiguration from './server-configuration'
import { HtmlContent } from './html-content'
import { useSafeReplaceQuery } from './use-safe-replace-query'

// TODO: Remove this whenever Betby is done testing and we move back into Gamer integration.

function useClient() {
  return React.useContext(Urql.Context)
}

const getBetbyToken = `
  query getBetbyToken {
    player {
      betbyToken
    }
  }
`

function usePreviousValue(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

function setOffsetTop(token) {
  if (window.innerWidth > 1000) {
    return 0
  } else {
    if (!token || window.innerWidth < 800) {
      return 46
    }
    return 56
  }
}

export function SportsbookDirect(props) {
  const ref = React.useRef(null)
  const [response] = Urql.useQuery({ query: getBetbyToken })
  const [shouldInitApp, setShouldInitApp] = React.useState(false)
  const [btRendererInited, setBtRendererInited] = React.useState(false)
  const [scrollbarWidth, setScrollbarWidth] = React.useState('0px')
  const history = ReactRouter.useHistory()
  const registerQuery = useSafeReplaceQuery({ register: 'me' })
  const loginQuery = useSafeReplaceQuery({ login: 'me' })
  const depositQuery = useSafeReplaceQuery({
    wallet: Constants.TransactionType.DEPOSIT,
  })
  const UrqlClient = useClient()
  const token = response?.data?.player?.betbyToken

  const { cdnPrefix, locale } = ServerConfiguration.useContext()
  const prevToken = usePreviousValue(token)
  const prevLanguage = usePreviousValue(locale.language)
  const brandId =
    cdnPrefix === 'production' ? '1965800149099876352' : '1994860287135719424'

  const scriptUrl =
    cdnPrefix === 'production'
      ? 'https://amuleto.sptpub.com/bt-renderer.min.js'
      : 'https://ui.invisiblesport.com/bt-renderer.min.js'

  const i18n = I18n.useI18n()

  // Makes sure client is available on window (script loaded properly)
  React.useEffect(() => {
    if (!btRendererInited) {
      const timerId = window.setInterval(() => {
        setBtRendererInited(typeof window.BTRenderer !== 'undefined')
      }, 250)
      return () => {
        clearInterval(timerId)
      }
    }
  }, [btRendererInited])

  const client = React.useMemo(() => {
    if (process.browser && btRendererInited) {
      return new window.BTRenderer()
    }
  }, [process.browser, btRendererInited])

  const widgetConfig = React.useMemo(
    () =>
      props.widgetName
        ? {
            widgetName: props.widgetName,
            widgetParams: {
              minFrameHeight: props.minFrameHeight,
              placeholder: props.widgetPlaceholder,
              onBannerClick: ({ url }) =>
                history.push(`/sports?bt-path=${url}`),
            },
          }
        : {},
    [props.widgetName, props.minFrameHeight, props.widgetPlaceholder]
  )
  // Initiates client
  React.useEffect(() => {
    if (shouldInitApp) {
      setShouldInitApp(false)
      const offsetTop = setOffsetTop(token)

      client.initialize({
        brand_id: brandId,
        token,
        themeName: 'amuletobet',
        lang: locale.language === 'pt' ? 'pt-br' : locale.language,
        target: document.getElementById('betby'),
        betSlipOffsetBottom: 0,
        betSlipOffsetTop: offsetTop,
        stickyTop: 0,
        betslipZIndex: 110,
        cssUrls: [
          'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
        ],
        fontFamilies: ['Montserrat, sans-serif', 'Roboto, sans-serif'],
        ...widgetConfig,
        onSessionRefresh: () => window.location.reload(),
        goToClick: ({ url }) => history.push(`/sports?bt-path=${url}`),
        onBannerClick: ({ url }) => history.push(`/sports?bt-path=${url}`),
        onLogin: () => history.push(`?${loginQuery}`),
        onRecharge: () => history.push(`?${depositQuery}`),
        onRegister: () => history.push(`?${registerQuery}`),
        onSportClick: () => history.push('/sports'),
        onTokenExpired: () =>
          UrqlClient.query(getBetbyToken)
            .toPromise()
            .then(res => res.data.player.betbyToken),
      })
    }
  }, [locale, token, shouldInitApp, client])

  // Hook for checking if app should be reinited.
  React.useEffect(() => {
    // Do nothing if there is no refrence
    if ((ref.current, btRendererInited)) {
      // Config has been updated; e.g. language was switched.
      if (
        !response.fetching &&
        (prevLanguage !== locale.language || prevToken !== token)
      ) {
        client.kill()
        setShouldInitApp(true)
      }
    }
  }, [
    token,
    locale,
    prevToken,
    prevLanguage,
    ref,
    setShouldInitApp,
    btRendererInited,
  ])

  React.useEffect(() => {
    const queries = {
      sm: '(max-width: 799px)',
      md: '(min-width: 800px) and (max-width: 999px)',
      lg: '(min-width: 1000px)',
    }
    const mediaQueryLists = {}
    const keys = R.keys(queries)

    function handleChange(isMatching) {
      const match = R.filter(query => query, isMatching)

      switch (R.head(R.keys(match))) {
        case 'sm': {
          client.updateOptions({ betSlipOffsetTop: 46 })
          break
        }
        case 'md': {
          if (token) {
            client.updateOptions({ betSlipOffsetTop: 56 })
          } else {
            client.updateOptions({ betSlipOffsetTop: 46 })
          }
          break
        }
        case 'lg': {
          client.updateOptions({ betSlipOffsetTop: 0 })
          break
        }
      }
    }
    function handleQueryListener() {
      const isMatching = R.reduce(
        (acc, media) => {
          acc[media] = Boolean(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        {},
        R.keys(queries)
      )

      handleChange(isMatching)
    }

    R.forEach(media => {
      mediaQueryLists[media] = window.matchMedia(queries[media])
    }, keys)

    R.forEach(media => {
      mediaQueryLists[media].addListener(handleQueryListener)
    }, keys)

    return () => {
      R.forEach(media => {
        mediaQueryLists[media].removeListener(handleQueryListener)
      }, keys)
    }
  }, [client])

  React.useEffect(() => {
    if (process.browser) {
      setScrollbarWidth(window.innerWidth - document.body.clientWidth + 'px')
    }
  }, [process.browser])

  // Launch client
  React.useEffect(() => {
    if (ref.current && brandId && btRendererInited) {
      setShouldInitApp(true)
    }
  }, [ref.current, brandId, btRendererInited])

  // Cleanup on unmount
  React.useEffect(() => {
    return () => {
      if (client) {
        client.kill()
      }
    }
  }, [client])

  return (
    <>
      <Helmet>
        <script src={scriptUrl} id="betby-script" />
      </Helmet>
      <Common.Box display="flex" flexDirection="column">
        <Common.Box
          id="betby"
          ref={ref}
          width={
            props.widgetName
              ? '100%'
              : ['100vw', null, `calc(100vw - 200px - ${scrollbarWidth})`]
          }
        />
        {i18n.hasTranslation(`sports.seo-content`) && (
          <Common.Box
            pt={2}
            maxWidth="1080px"
            margin="0 auto"
            textAlign="left"
            fontSize={2}
            px={2}
          >
            <HtmlContent
              html={{
                __html: i18n.translate(`sports.seo-content`),
              }}
            />
          </Common.Box>
        )}
      </Common.Box>
    </>
  )
}

SportsbookDirect.propTypes = {
  widgetName: PropTypes.string,
  widgetPlaceholder: PropTypes.string,
  minFrameHeight: PropTypes.number,
}
