import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import { Transition } from 'react-transition-group'
import { differenceInMinutes } from 'date-fns'

import * as locks from '@rushplay/compliance/locks'
import * as t from '@rushplay/theme'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Herz from '@rushplay/herz'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Player from './player'
import * as Constants from './constants'
import * as Configuration from './configuration'
import * as Cookies from './cookies-module'
import * as icons from './icons'
import * as ServerConfig from './server-configuration'
import * as Analytics from './analytics'
import { Balance } from './balance'
import { Button } from './button'
import { Divider } from './divider'
import { LocationInfo } from './locale'
import { LoyaltyMenuItem } from './loyalty-menu-item'
import { LoyaltyProgramRoundProgressBar } from './loyalty-program-round-progress-bar'
import { MenuItem } from './menu-item'
import { MenuNotificationBadge } from './menu-notification-badge'
import { PromotionsMenuItem } from './promotions-menu-item'
import { ScrollLock } from './scroll-lock'
import { SocialMediaLinks } from './social-media-links'
import { SupportChatMenuItem } from './support-chat-menu-item'
import { WalletMenuItem } from './wallet-menu-item'
import { useGameInfoUrlQuery } from './use-game-info-url-query'
import { useMenuQueries } from './use-menu-queries'

const duration = 200
const defaultStyle = {
  transition: `${duration}ms ease-in-out`,
  transform: 'translateY(-100%)',
  opacity: 0,
  display: 'none',
}

const transitionStyles = {
  entering: { opacity: 0, display: 'flex' },
  entered: { opacity: 1, transform: 'translateY(0)', display: 'flex' },
  exiting: { opacity: 0, transform: 'translateY(-100%)' },
  exited: { opacity: 0, display: 'none' },
}

const Logotype = styled.div`
  ${css({
    width: ['110px', null, '160px'],
    height: ['30px', null, '65px'],
    backgroundImage: [
      'url(/images/amuletobet-logo-long.svg)',
      null,
      'url(/images/amuletobet-logo.svg)',
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  })};

  @media screen and (max-width: 320px) {
    background-image: url(/images/amuletobet-icon.svg);
    width: 30px;
    height: 30px;
  }
`

const Wrapper = styled.header`
  ${props =>
    css({
      backgroundColor: 'nav',
      position: 'sticky',
      top: '0px',
      left: '0px',
      height: [null, null, '100vh'],
      width: ['100%', null, '200px'],
      display: 'flex',
      backgroundImage: props.menuOpened
        ? `linear-gradient(180deg, #000000 20px, rgba(0, 0, 0, 0.6) 70px, #000000 70%, rgba(0, 0, 0, 0.5) 100%)`
        : [
            `linear-gradient(90deg, #000000 1.53%, rgba(0, 0, 0, 0) 70.52%)`,
            null,
            `linear-gradient(180deg, #000000 180px, rgba(0, 0, 0, 0.6) 255px, #000000 70%, rgba(0, 0, 0, 0.5) 100%)`,
          ],
      flexDirection: 'column',
      overflowY: ['hidden', null, 'auto'],
      overflowX: 'hidden',
      zIndex: 111,
    })};

  &::before {
    content: '';
    position: absolute;
    ${css({
      width: ['100px', '200px'],
    })};
    height: 350px;
    transform-origin: top right;
    transform: rotate(30deg);
    background-image: linear-gradient(
      180deg,
      #4d0041 9.38%,
      #4d0041 52.6%,
      rgba(77, 0, 65, 0) 100%
    );
  }

  flex-shrink: 0;
`

const Menu = styled.div`
  ${css({
    flexDirection: 'column',
    justifyContent: [null, null, 'space-between'],
    height: ['var(--window-inner-height, 100vh)', null, 'auto'],
    overflowY: ['auto', null, 'scroll'],
  })};

  -webkit-overflow-scrolling: touch;

  // These styles are only to override the animation styles from small screen
  ${t.mediaQuery.md`
    display: inline-flex !important;
    transform: translate(0) !important;
    opacity: 1 !important;
  `}

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }
`

export function MainMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const nodeRef = React.useRef(null)
  const [returningPlayer] = Cookies.useCookie('returning_player')
  const i18n = I18n.useI18n()
  const { authenticated, createdAt } = Herz.Auth.useSession()
  const location = ReactRouter.useLocation()
  const { country, locale } = ServerConfig.useContext()
  const {
    depositQuery,
    withdrawalQuery,
    loyaltyQuery,
    loginQuery,
    registerQuery,
  } = useMenuQueries()
  const dispatch = ReactRedux.useDispatch()

  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const balanceCents = ReactRedux.useSelector(state =>
    Player.getBalanceCents(state.player)
  )
  const hasLowBalance = Boolean(balanceCents < 1000)
  const gameInfoQuery = useGameInfoUrlQuery()
  const isMobile = Constants.ClientType.MOBILE_BROWSER === clientType
  const isLocked = ReactRedux.useSelector(
    state => locks.isInitialized(state.locks) && locks.hasLocks(state.locks)
  )

  React.useEffect(() => {
    setIsMenuOpen(false)
  }, [location])

  if (['classic', 'blitz'].includes(gameInfoQuery.mode) && isMobile) {
    return null
  }

  return (
    <Wrapper data-testid="main-menu" menuOpened={isMenuOpen}>
      {isMenuOpen && !Constants.isDesktop && <ScrollLock />}
      {/* Topbar */}
      <Common.Box
        color="g-text"
        py={[0, null, 1]}
        pl={[0, null, '0px']}
        pr={[0, null, 1]}
        position="sticky"
        top="0px"
        left="0px"
        zIndex="1"
        display="flex"
        justifyContent={['space-between', null, 'stretch']}
        alignItems={['center', null, 'stretch']}
        flexDirection={['row', null, 'column']}
        flexShrink="0"
      >
        <Common.Box
          display="flex"
          justifyContent="center"
          pl={[null, null, 1]}
          py={[null, null, 2]}
        >
          <ReactRouter.Link to="/" data-testid="main-menu-logotype">
            <Logotype title="Amuletobet" />
          </ReactRouter.Link>
        </Common.Box>
        <Common.Flex>
          <Common.Box
            pt={[null, null, 1]}
            pl={[null, null, 3]}
            justifyContent="center"
            alignItems="center"
            flexDirection={['row-reverse', null, 'column']}
            display="flex"
          >
            {authenticated ? (
              <ReactRouter.Link
                to={`?${depositQuery}`}
                data-testid="main-menu-balance"
              >
                <Balance />
              </ReactRouter.Link>
            ) : !country.enabled ? (
              <Common.Space pl={0}>
                <ReactRouter.Link
                  to={`?${loginQuery}`}
                  data-testid="main-menu-login"
                >
                  <Button variant="primary" fontSize={[1, null, 3]}>
                    {i18n.translate('main-menu.login')}
                  </Button>
                </ReactRouter.Link>
              </Common.Space>
            ) : (
              <Common.Box
                display="flex"
                flexDirection={['row-reverse', null, 'column']}
                alignItems="center"
              >
                <Common.Space pl={0} pb={[null, null, 0]}>
                  <ReactRouter.Link
                    to={`?${registerQuery}`}
                    data-testid="main-menu-register"
                  >
                    <Button variant="primary" fontSize={[1, null, 3]}>
                      {i18n.translate('main-menu.register')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Space>
                <Common.Space pl={0} pb={[null, null, 0]}>
                  <ReactRouter.Link
                    to={`?${loginQuery}`}
                    data-testid="main-menu-login"
                  >
                    <Button variant="primary-outlined" fontSize={[1, null, 3]}>
                      {i18n.translate('main-menu.login')}
                    </Button>
                  </ReactRouter.Link>
                </Common.Space>
              </Common.Box>
            )}
            {authenticated && (
              <Common.Space pr={[0, null, '0px']} pt={[null, null, 0]}>
                <ReactRouter.Link
                  to={`?${loyaltyQuery}`}
                  data-testid="main-menu-loyalty-program-progress"
                  onClick={() =>
                    dispatch(
                      Analytics.cashVisit({
                        eventAction: 'Topbar',
                        title: 'main-menu-loyalty-program-progress',
                        path: `${locale.slug}${location.pathname}`,
                      })
                    )
                  }
                >
                  <LoyaltyProgramRoundProgressBar />
                </ReactRouter.Link>
              </Common.Space>
            )}
          </Common.Box>
          <Common.Box
            data-testid="main-menu-menu-toggler"
            display={['inline-flex', null, 'none']}
            alignItems="center"
            pl={0}
            fontSize="29px"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <icons.Clear />
            ) : (
              <MenuNotificationBadge>
                <icons.Menu />
              </MenuNotificationBadge>
            )}
          </Common.Box>
        </Common.Flex>
      </Common.Box>

      {/* Navigations */}
      <Transition
        nodeRef={nodeRef}
        in={isMenuOpen}
        timeout={{
          exit: duration,
        }}
      >
        {state => (
          <Menu
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={nodeRef}
          >
            <nav>
              <MenuItem
                disabled={isLocked}
                icon={icons.Ball}
                iconOutlined={icons.Ball}
                testId="main-menu-sport"
                to="/sports"
              >
                {i18n.translate('main-menu.sport')}
              </MenuItem>

              <MenuItem
                disabled={isLocked}
                icon={icons.ViewModule}
                iconOutlined={icons.ViewModuleOutlined}
                testId="main-menu-casino"
                to="/casino"
              >
                {i18n.translate('main-menu.casino')}
              </MenuItem>
              <MenuItem
                disabled={isLocked}
                icon={icons.Videocam}
                iconOutlined={icons.VideocamOutlined}
                testId="main-menu-live-casino"
                to="/live-casino"
                textTransform="uppercase"
              >
                {i18n.translate('main-menu.live-casino')}
              </MenuItem>
              {process.env.RAZZLE_EXPERTS_AREA_ENABLED === '1' && (
                <MenuItem
                  disabled={isLocked}
                  icon={icons.Edit}
                  testId="main-menu-experts-area-item"
                  to="/experts-area"
                >
                  {i18n.translate('main-menu.experts-area')}
                </MenuItem>
              )}
              <PromotionsMenuItem
                disabled={isLocked}
                testId="main-menu-promotion-notifications"
              >
                {i18n.translate('main-menu.promotion-notifications')}
              </PromotionsMenuItem>
              <MenuItem
                disabled={isLocked || !authenticated}
                icon={icons.Face}
                iconOutlined={icons.FaceOutlined}
                testId="main-menu-account"
                to="/account"
              >
                {i18n.translate('main-menu.account')}
              </MenuItem>
              <SupportChatMenuItem testId="main-menu-support-chat">
                {i18n.translate('main-menu.support-chat')}
              </SupportChatMenuItem>
              <Common.Space py={0} px={1}>
                <Divider />
              </Common.Space>
              <WalletMenuItem
                animate={hasLowBalance}
                disabled={isLocked || !authenticated}
                highlightColor="primary"
                icon={icons.BankNote}
                testId="main-menu-wallet-deposit"
                to={`?${depositQuery}`}
              >
                {i18n.translate('main-menu.deposit')}
              </WalletMenuItem>
              <WalletMenuItem
                disabled={isLocked || !authenticated}
                highlightColor="secondary"
                icon={icons.CoinStack}
                testId="main-menu-wallet-withdraw"
                to={`?${withdrawalQuery}`}
              >
                {i18n.translate('main-menu.withdraw')}
              </WalletMenuItem>
              <LoyaltyMenuItem
                disabled={isLocked || !authenticated}
                testId="main-menu-loyalty-program"
                to={`?${loyaltyQuery}`}
                onClick={() =>
                  dispatch(
                    Analytics.cashVisit({
                      eventAction: 'Sidebar',
                      title: `${i18n.translate('main-menu.loyalty-program')}`,
                      path: `${locale.slug}${location.pathname}`,
                    })
                  )
                }
              >
                {i18n.translate('main-menu.loyalty-program')}
              </LoyaltyMenuItem>
            </nav>
            <Common.Box
              py={1}
              pb={['50px', null, 1]}
              color="g-text"
              textAlign="center"
              fontSize={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexShrink="0"
            >
              {authenticated ? (
                <React.Fragment>
                  <Common.Box
                    opacity="0.46"
                    data-testid="main-menu.current-session-time"
                  >
                    <React.Fragment>
                      <span>
                        {i18n.translate('main-menu.current-session-time')}{' '}
                      </span>
                      <Common.Timestamp>
                        {timestamp =>
                          `${differenceInMinutes(
                            new Date(timestamp),
                            new Date(createdAt)
                          )} min`
                        }
                      </Common.Timestamp>
                    </React.Fragment>
                  </Common.Box>
                  <ReactRouter.Link
                    to="/logout"
                    data-testid="main-menu-log-out"
                  >
                    <Common.Space p={0}>
                      {i18n.translate('main-menu.log-out')}
                    </Common.Space>
                  </ReactRouter.Link>
                </React.Fragment>
              ) : returningPlayer && country.enabled ? (
                <React.Fragment>
                  <Common.Box opacity="0.46">
                    {i18n.translate('main-menu.not-a-member')}
                  </Common.Box>
                  <ReactRouter.Link
                    to={`?${registerQuery}`}
                    data-testid="main-menu-manual-register"
                  >
                    <Common.Space p={0}>
                      {i18n.translate('main-menu.register')}
                    </Common.Space>
                  </ReactRouter.Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Common.Box opacity="0.46">
                    {i18n.translate('main-menu.already-have-an-account')}
                  </Common.Box>
                  <ReactRouter.Link
                    to={`?${loginQuery}`}
                    data-testid="main-menu-login"
                  >
                    <Common.Space p={0}>
                      {i18n.translate('main-menu.login')}
                    </Common.Space>
                  </ReactRouter.Link>
                </React.Fragment>
              )}
              {!authenticated && <LocationInfo />}
              <SocialMediaLinks />
            </Common.Box>
          </Menu>
        )}
      </Transition>
    </Wrapper>
  )
}
