import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import * as emotion from '@emotion/core'
import styled from '@emotion/styled'

import { useGameInfoUrlQuery } from './use-game-info-url-query'

export function baseStyles(props) {
  // I'm writing them manually because you can't call functions inside css
  return emotion.css`
    display: flex;
    align-items: center;
    font-size: ${props.theme.fontSizes[2]}px;
    padding: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;

    user-select: none;

    &.active {
      color: ${props.theme.colors['nav-highlight']};
    }

    &:active,
    &.active {
      color: ${props.theme.colors['nav-highlight']};
    }
  `
}

const DisabledMenuLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &.active {
    color: ${t.color('g-text', 0.3)};
  }
`

const MenuLink = styled(ReactRouter.NavLink)`
  ${baseStyles};
  color: ${t.color('g-text')};
`

export function MenuItem(props) {
  const Icon = props.icon
  const IconOutlined = props.iconOutlined

  const gameInfoQuery = useGameInfoUrlQuery()
  const match = ReactRouter.useRouteMatch({
    path: props.to,
  })

  if (props.disabled) {
    return (
      <DisabledMenuLink data-testid={props.testId}>
        {IconOutlined && <IconOutlined />}
        <common.Box
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
          fontFamily="head"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {props.children}
        </common.Box>
      </DisabledMenuLink>
    )
  }
  return (
    <MenuLink
      data-testid={props.testId}
      to={props.to}
      isActive={match => {
        if (gameInfoQuery.referrer) {
          return (
            R.equals(gameInfoQuery.referrerPath, props.to) ||
            R.equals(`/${gameInfoQuery.referrer}`, props.to)
          )
        }
        // We don't want loyalty-program to be active
        if (R.test(/loyalty-program=cashback/, props.to)) {
          return
        }

        return match
      }}
      exact={props.exact}
      onClick={props.onClick}
    >
      {match ? <Icon /> : <IconOutlined />}
      <common.Box paddingLeft={1} display="inline-flex" fontFamily="head">
        {props.children}
      </common.Box>
    </MenuLink>
  )
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  iconOutlined: PropTypes.string.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
