import * as EmotionTheming from 'emotion-theming'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as ServerConfiguration from '../server-configuration'
import * as Theming from '../theming'
import { Divider } from '../divider'
import { Heading } from '../heading'

const Language = styled.span`
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

export function LocationPicker() {
  const theme = EmotionTheming.useTheme()
  const i18n = I18n.useI18n()
  const configuration = ServerConfiguration.useContext()
  const session = Herz.Auth.useSession()

  if (session.authenticated) {
    return <ReactRouter.Redirect to="/" />
  }

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="change-location-background"
        backgroundImage={[
          `linear-gradient(180deg,
            ${theme.colors['change-location-gradient-top']} 50px,
            rgba(0,0,0,0.4) 350px,
          ${theme.colors['change-location-gradient-top']} 100%)`,
        ]}
        backgroundRepeat="no-repeat"
        backgroundPosition="left top"
        color="heading-text"
        px={[1, 2]}
        py={1}
        width="100%"
      >
        <Heading
          level={2}
          color="heading-text"
          data-testid="change-location.title"
        >
          {i18n.translate('change-location.title')}
        </Heading>
        <Common.Space py={[1, 2]}>
          <Divider />
        </Common.Space>
        <Common.Box
          display="grid"
          gridTemplateColumns={[
            'repeat(2, minmax(25%, 1fr))',
            'repeat(auto-fit, minmax(25%, 1fr))',
          ]}
          gridGap={[0, 1]}
          style={{ listStyle: 'none' }}
        >
          {configuration.countries.map(country => (
            <Common.Box as="li" key={country.alpha2} pr={0} py={0}>
              <Common.Box
                fontWeight="bold"
                data-testid={`change-location.country.${country.alpha2}`}
              >
                {i18n.translate(`l10n.region.${country.alpha2.toLowerCase()}`)}
              </Common.Box>
              <ul>
                {country.locales.map(locale => (
                  <Common.Box as="li" key={locale.code} fontSize={1} pt={0}>
                    <a
                      href={`/${locale.slug}/?confirm_locale=1`}
                      hrefLang={locale.slug}
                    >
                      <Language
                        data-testid={`change-location.language.${locale.language}`}
                      >
                        {i18n.translate(
                          `l10n.language.${locale.language.toLowerCase()}`
                        )}
                      </Language>
                    </a>
                  </Common.Box>
                ))}
              </ul>
            </Common.Box>
          ))}
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

// For @loadable/component
export default LocationPicker
