import * as ReactRouter from 'react-router-dom'
import React from 'react'
import * as Herz from '@rushplay/herz'
import { RegistrationDrawer } from './registration-drawer'

export function Registration() {
  const location = ReactRouter.useLocation()
  const history = ReactRouter.useHistory()
  const session = Herz.Auth.useSession()

  // Redirect users from old registration links to new one
  React.useEffect(() => {
    if (location.hash === '#register') {
      history.push({
        pathname: location.pathname,
        search: '?register=me',
      })
    }
  }, [location.hash])

  if (session.authenticated) {
    return null
  }

  return <RegistrationDrawer />
}
