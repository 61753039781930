import * as R from 'ramda'
import { connect } from 'react-redux'

import * as notifications from '@rushplay/notifications'
import * as api from '@rushplay/api-client'
import * as Processes from '@rushplay/processes'

import * as player from '../player'

import { LoyaltyDrawer as Component } from './loyalty-drawer'

export const LoyaltyDrawer = R.compose(
  connect(
    state => {
      const claimableBalanceCents = player.getClaimableBalanceCents(
        state.player
      )

      return {
        currentSpin: player.getBetBackProgress(state.player),
        cycleLength: player.getBetBackDropPoint(state.player),
        disabled: claimableBalanceCents === 0,
        loading: Processes.isRunning(state.processes, {
          ids: ['BETBACK_LOADING'],
        }),
        mainBetBalanceCents: player.getMainBetBackBalanceCents(state.player),
        nextBetBalanceCents: player.getNextBetBackBalanceCents(state.player),
        progress: player.getBetBackProgressPercentage(state.player),
        claimableBalanceCents,
      }
    },
    dispatch => ({
      onClaim: onSuccess =>
        dispatch([
          Processes.start('BETBACK_LOADING'),
          api.claimBetBack({
            success: res => {
              onSuccess(R.path(['result', 'totalBalanceCents'], res.value))
              return [
                Processes.stop('BETBACK_LOADING'),
                player.updateProgress(res.value.result),
              ]
            },
            failure: res => [
              Processes.stop('BETBACK_LOADING'),
              notifications.add({
                message: `errors.${res.value.message || 'general.unknown'}`,
                level: 'error',
              }),
            ],
            version: 1,
          }),
        ]),
    })
  )
)(Component)
