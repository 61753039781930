import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import { baseStyles } from './menu-item'

const attentionAnimation = keyframes`
  0% {
   transform: rotate(0);
  }

  15%{
    transform: rotate(0);
  }

  35%{
    transform: rotate(20deg);
  }

  40%{
    transform: rotate(-20deg);
  }

  45%{
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(-20deg);
  }

  55%{
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
`

const AnimatedIcon = styled.div`
  transform: rotate(0);
  &.animated {
    animation-name: ${attentionAnimation};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`

const DisabledWalletLink = styled.span`
  ${baseStyles};
  color: ${t.color('g-text', 0.3)};
  opacity: 0.35;
  cursor: not-allowed;

  &:hover,
  &:active {
    background-color: initial;
    box-shadow: initial;
  }
`

const WalletLink = styled(ReactRouter.Link)`
  ${baseStyles};
  color: ${props => t.color(props.color)(props)};

  &:active {
    box-shadow: inset 4px 0 0 0 ${props => t.color(props.color)(props)};
  }
`

export function WalletMenuItem(props) {
  const Icon = props.icon

  if (props.disabled) {
    return (
      <DisabledWalletLink data-testid={props.testId}>
        <Icon />
        <common.Box
          fontSize={2}
          paddingLeft={1}
          display="inline-flex"
          color="g-text"
        >
          {props.children}
        </common.Box>
      </DisabledWalletLink>
    )
  }

  return (
    <WalletLink
      data-testid={props.testId}
      color={props.highlightColor}
      to={props.to}
      onClick={props.onClick}
    >
      <AnimatedIcon className={props.animate ? 'animated' : ''}>
        <Icon />
      </AnimatedIcon>
      <common.Box
        fontSize={2}
        paddingLeft={1}
        display="inline-flex"
        color="g-text"
      >
        {props.children}
      </common.Box>
    </WalletLink>
  )
}

WalletMenuItem.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  highlightColor: PropTypes.string,
  icon: PropTypes.func.isRequired,
  testId: PropTypes.string,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
