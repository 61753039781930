import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Herz from '@rushplay/herz'
import { lazy as loadable } from '@loadable/component'

import * as Cookies from './cookies-module'

const LoginPage = loadable(() => import('./login-page'))
const LandingPage = loadable(() => import('./landing-page'))

export function Home() {
  const { authenticated } = Herz.Auth.useSession()
  const [returningPlayer] = Cookies.useCookie('returning_player')

  if (!authenticated) {
    if (returningPlayer) {
      return <LoginPage />
    }

    return <LandingPage />
  }

  return (
    <ReactRouter.Redirect
      exact
      from="/"
      to={{
        pathname: '/sports',
        search: returningPlayer ? null : '?wallet=deposit',
      }}
    />
  )
}
