import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as ClientConfig from '../client-configuration'
import * as ServerConfig from '../server-configuration'
import * as Icons from '../icons'
import { Button } from '../button'
import { HtmlContent } from '../html-content'

function Banner(props) {
  return (
    <Common.Box
      alignItems="center"
      backgroundColor="navHover"
      display={['block', 'flex']}
      fontSize={1}
      p={0}
    >
      <Common.Box alignItems="center" display="flex" mr="auto">
        {props.icon}
        <Common.Box fontSize={1} lineHeight={1.25} p={0}>
          {props.children}
        </Common.Box>
      </Common.Box>
      {props.actions && (
        <Common.Box
          alignItems="center"
          display="flex"
          justifyContent="flex-end"
        >
          {props.actions}
        </Common.Box>
      )}
    </Common.Box>
  )
}

Banner.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  icon: PropTypes.node,
}

export function LocationBanner() {
  const { country, locale } = ServerConfig.useContext()
  const i18n = I18n.useI18n()
  const [countryCode] = ClientConfig.useCountryCode(true)
  const [playerLocale, setPlayerLocale] = ClientConfig.useLocale()

  if (!country.enabled) {
    return (
      <Banner
        actions={
          <Common.Box
            as={ReactRouter.Link}
            display="block"
            p={0}
            to="/change-location"
          >
            <u>{i18n.translate('l10n.confirmation.change')}</u>
          </Common.Box>
        }
        icon={
          <Common.Box fontSize={5} color="warning" p={0}>
            <Icons.Warning />
          </Common.Box>
        }
      >
        <HtmlContent>
          {i18n.translate('errors.unsupported-country')}
        </HtmlContent>
      </Banner>
    )
  }

  if (countryCode == null) {
    return (
      <Banner
        actions={
          <React.Fragment>
            <Common.Box
              as={ReactRouter.Link}
              display="block"
              p={0}
              to="/change-location"
            >
              <u>{i18n.translate('l10n.confirmation.change')}</u>
            </Common.Box>
            <Common.Box p={0}>
              <Button
                fontSize={1}
                variant="primary"
                onClick={() => {
                  setPlayerLocale(locale.language, country.alpha2)
                }}
              >
                {i18n.translate('l10n.confirmation.confirm')}
              </Button>
            </Common.Box>
          </React.Fragment>
        }
      >
        <HtmlContent>
          {i18n.translate('errors.unconfirmed-country', {
            country: i18n.translate(`l10n.region.${country.alpha2}`),
          })}
        </HtmlContent>
      </Banner>
    )
  }

  if (country.alpha2 !== countryCode) {
    return (
      <Banner
        actions={
          <React.Fragment>
            <Common.Box
              as="a"
              display="block"
              href={`/${playerLocale.language}-${playerLocale.region}/`}
              p={0}
              style={{ whiteSpace: 'nowrap' }}
            >
              <u>
                {i18n.translate('l10n.confirmation.revert', {
                  selected: i18n.translate(`l10n.region.${countryCode}`),
                })}
              </u>
            </Common.Box>
            <Common.Box p={0}>
              <Button
                fontSize={1}
                variant="primary"
                onClick={() => {
                  setPlayerLocale(locale.language, country.alpha2)
                }}
              >
                {i18n.translate('l10n.confirmation.confirm')}
              </Button>
            </Common.Box>
          </React.Fragment>
        }
        icon={
          <Common.Box fontSize={5} color="warning" p={0}>
            <Icons.Warning />
          </Common.Box>
        }
      >
        <HtmlContent>
          {i18n.translate('errors.incorrect-country', {
            current: i18n.translate(`l10n.region.${country.alpha2}`),
            selected: i18n.translate(`l10n.region.${countryCode}`),
          })}
        </HtmlContent>
      </Banner>
    )
  }

  return null
}

export default LocationBanner
