import * as ReactRedux from 'react-redux'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './configuration'

export function ApgSeal() {
  const scriptId = ReactRedux.useSelector(state =>
    Configuration.getApgSealScriptId(state.configuration)
  )

  React.useEffect(() => {
    window.APG && window.APG.init()
  }, [])

  if (!scriptId) {
    return null
  }

  return (
    <React.Fragment>
      <Helmet>
        <script
          defer="defer"
          type="text/javascript"
          src={`https://${scriptId}.snippet.antillephone.com/apg-seal.js`}
        />
      </Helmet>
      <div
        id="apg-seal-container"
        data-apg-seal-id={scriptId}
        data-apg-image-size="32"
        data-apg-image-type="basic-small"
      />
    </React.Fragment>
  )
}
